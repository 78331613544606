import { graphql } from "gatsby"
import React from "react"
import { Collection } from "../../components/Collection"
import Layout from "../../layouts/MainLayout"

export default function Activities({ data }) {
    let CS = [],
        PES = [],
        WIE = [],
        Collab = [],
        All = [],
        EDSOC = []
    data.allFile.nodes.forEach((element, index) => {
        if (
            new Date(element.childMarkdownRemark.frontmatter.date).toISOString() <
            new Date().toISOString()
        ) {
            let By = element.childMarkdownRemark.frontmatter.by.split(",")

            if (By.includes("CS")) {
                CS.push(data.allFile.nodes[index])
            }
            if (By.includes("PES")) {
                PES.push(data.allFile.nodes[index])
            }
            if (By.includes("WIE")) {
                WIE.push(data.allFile.nodes[index])
            }
            if (By.includes("COLLAB")) {
                Collab.push(data.allFile.nodes[index])
            }
            if (By.includes("EDSOC")) {
                EDSOC.push(data.allFile.nodes[index])
            }
            All.push(data.allFile.nodes[index])
        }
    })

  return (
    <Layout>
      <div className="banner">Latest Activities</div>
      <div className="container">
        <nav className="my-4">
          <div
            className="nav nav-pills justify-content-center" //justify-content-md-start
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="nav-all-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-all"
              type="button"
              role="tab"
              aria-controls="nav-all"
              aria-selected="true"
              style={{ margin: '0px 5px' }}
            >
              All
            </button>
            <button
              className="nav-link"
              id="nav-cs-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-cs"
              type="button"
              role="tab"
              aria-controls="nav-cs"
              aria-selected="false"
              style={{ margin: '0px 5px' }}
            >
              CS
            </button>
            <button
              className="nav-link"
              id="nav-pes-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-pes"
              type="button"
              role="tab"
              aria-controls="nav-pes"
              aria-selected="false"
              style={{ margin: '0px 5px' }}
            >
              PES
            </button>
            <button
              className="nav-link"
              id="nav-edsoc-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-edsoc"
              type="button"
              role="tab"
              aria-controls="nav-edsoc"
              aria-selected="false"
              style={{ margin: '0px 5px' }}
            >
              Edsoc
            </button>
            <button
              className="nav-link"
              id="nav-wie-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-wie"
              type="button"
              role="tab"
              aria-controls="nav-wie"
              aria-selected="false"
              style={{ margin: '0px 5px' }}
            >
              Wie
            </button>
            <button
              className="nav-link"
              id="nav-collab-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-collab"
              type="button"
              role="tab"
              aria-controls="nav-collab"
              aria-selected="false"
              style={{ margin: '0px 5px' }}
            >
              {/* Collab */}
              <span className="d-none d-md-block">Collabrated </span>
              <span className="d-md-none">Collab</span>
            </button>
          </div>
        </nav>
        <div className="tab-content my-5" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-all"
            role="tabpanel"
            aria-labelledby="nav-all-tab"
          >
            <Collection data={All} />
          </div>
          <div
            className="tab-pane fade"
            id="nav-cs"
            role="tabpanel"
            aria-labelledby="nav-cs-tab"
          >
            <Collection data={CS} />
          </div>
          <div
            className="tab-pane fade"
            id="nav-pes"
            role="tabpanel"
            aria-labelledby="nav-pes-tab"
          >
            <Collection data={PES} />
          </div>
          <div
            className="tab-pane fade"
            id="nav-edsoc"
            role="tabpanel"
            aria-labelledby="nav-edsoc-tab"
          >
            <Collection data={EDSOC} />
          </div>
          <div
            className="tab-pane fade"
            id="nav-wie"
            role="tabpanel"
            aria-labelledby="nav-wie-tab"
          >
            <Collection data={WIE} />
          </div>
          <div
            className="tab-pane fade"
            id="nav-collab"
            role="tabpanel"
            aria-labelledby="nav-collab-tab"
          >
            <Collection data={Collab} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allActivities {
    allFile(
      filter: {
        relativePath: { glob: "data/events/**/**/*" }
        extension: { eq: "md" }
        childMarkdownRemark: {frontmatter: {type: {eq: "SUCCESS"}}}
      }
      sort: { fields: childrenMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            by
            date
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            slug
            title
            type
          }
        }
      }
    }
  }
`
